import { handleHttpErrors } from "#js/components/http"

/**
 * An async per user storage persisted server side.
 */
export class UserStorage {
  constructor() {
    this.endpoint = "/accounts/user/storage/"
  }

  /**
   * Get item from storage, return null if not found.
   * @param {string} key - Key to get.
   * @returns {Promise<string|null>} - Promise that resolves to value or null.
   */
  async getItem(key) {
    const response = await fetch(`${this.endpoint}${key}`)
    if (response.status === 404) {
      return null
    }
    handleHttpErrors(response)
    return await response.text()
  }

  /**
   * Set item in storage.
   * @param {string} key - Key to set.
   * @param {string} value - Value to set.
   * @returns {Promise<void>} - Promise that resolves when set.
   */
  async setItem(key, value) {
    const response = await fetch(`${this.endpoint}${key}`, {
      method: "POST",
      headers: {
        "X-CSRFToken": globalThis.csrfToken ||
          document.querySelector('input[name="csrfmiddlewaretoken"]').value,
      },
      body: value.toString(),
    })
    handleHttpErrors(response)
  }

  /**
   * Remove item from storage.
   * @param {string} key - Key to remove.
   * @returns {Promise<void>} - Promise that resolves when removed.
   */
  async removeItem(key) {
    const response = await fetch(`${this.endpoint}${key}`, {
      method: "DELETE",
      headers: {
        "X-CSRFToken": globalThis.csrfToken ||
          document.querySelector('input[name="csrfmiddlewaretoken"]').value,
      },
    })
    handleHttpErrors(response)
  }
}
